import moment from 'moment';

import EzekiaLocalStorage from '@js/core/EzekiaLocalStorage.js';
import symbols from '../../currency_symbols.json';

let currencies = {};

// salary default keys have been moved to positionTypes.js!
let response = null;

// Toggle this value between 'currencies.timeout' and 'currencies.timeout1'
// when updating the currencies.
const TIMEOUT_KEY = 'currencies.timeout';
const CURRENCIES_KEY = 'currencies';
const DEFAULT_CURRENCY = 'GBP';

const date = moment.utc();

function fetchCurrenciesFromApi() {
    return $.ajax('/api/currency')
        .then((apiResponse) => {
            currencies = _.mapValues(apiResponse.data, (rate, code) => ({
                rate: rate,
                symbol: symbols[code],
            }));
            if (!_.isEmpty(currencies)) {
                saveCurrenciesToLocalStorage(currencies);
            } else {
                notify(trans('nal-lang.currency-load-failed'), 'alert', false);
            }
        });
}

function saveCurrenciesToLocalStorage(currencies) {
    EzekiaLocalStorage.set(CURRENCIES_KEY, JSON.stringify(currencies));
    EzekiaLocalStorage.set(TIMEOUT_KEY, date.add(1, 'days').startOf('day').valueOf());
}

function loadCurrenciesFromLocalStorage() {
    const currenciesTimeout = EzekiaLocalStorage.get(TIMEOUT_KEY);
    if (currenciesTimeout > date.valueOf()) {
        currencies = JSON.parse(EzekiaLocalStorage.get(CURRENCIES_KEY)) || {};
        return !_.isEmpty(currencies);
    }
    return false;
}
function prefetchCurrencies() {
    if (response) {
        return response;
    }

    if (loadCurrenciesFromLocalStorage()) {
        return Promise.resolve();
    }

    response = fetchCurrenciesFromApi();
    return response;
}

export {
    currencies,
    prefetchCurrencies,
    DEFAULT_CURRENCY,
};